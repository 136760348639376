import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import {
  CircularProgress,
  Box,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Design Core components
import { Card, CardHeader, CardBody } from "components/Card";
import { GridContainer, GridItem } from "components/Grid";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";

// Action Imports
import {
  getTransactions,
  getMoreTransactions,
  openTransaction,
} from "../../modules/transactions/transactionsActions";

// Component Imports
import TransactionModal from "./TransactionModal";
import ExportModal from "./ExportModal";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "27px 0 0 0",
    position: "relative",
    paddingBottom: "10px",
    verticalAlign: "unset",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const sanitizePayload = (data) =>
  Object.keys(data).reduce((acc, curr) => {
    if (data[curr]) {
      acc[curr] = data[curr];
    }

    return acc;
  }, {});

function TableList() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [where, setWhere] = useState({
    status: "",
    plan: "",
  });
  const [params, setParams] = useState({
    limit: 30,
    from: format(new Date(), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  });
  const [activeQueryParams, setActiveQueryParams] = useState({ limit: 30 });
  const [showExportModal, setShowExportModal] = useState(false);

  const setQueryParams = (e) => {
    e.preventDefault();
    if (e.target.value != "") {
      setParams({ ...params, [e.target.name]: e.target.value });
    } else {
      delete params[e.target.name];
    }
  };
  const setWhereClause = (e) => {
    e.preventDefault();
    if (e.target.value != "") {
      setWhere({ ...where, [e.target.name]: e.target.value });
    } else {
      delete where[e.target.name];
    }
  };

  const loadView = () => {
    const params = sanitizePayload(activeQueryParams.params);
    const where = sanitizePayload(activeQueryParams.where);

    dispatch(getMoreTransactions({ page, params, where }));
  };

  const { transactions, loading, pages } = useSelector(
    (state) => state.transactions
  );

  useEffect(() => {
    if (page !== 1) {
      loadView();
    }
  }, [page]);

  const search = () => {
    setPage(1);
    setActiveQueryParams({ params, where });
    dispatch(
      getTransactions(
        { ...sanitizePayload(params) },
        { ...sanitizePayload(where) }
      )
    );
  };
  const reset = () => {
    dispatch(getTransactions());
    setActiveQueryParams({ limit: 30 });
    setParams({ limit: 30 });
    setPage(1);
    const fields = document.querySelectorAll(".MuiInputBase-input");
    for (let key = 0; key < fields.length; key++) {
      fields[key].value = "";
    }
  };

  const formattedTableData = () => {
    return transactions.map((transaction, index) => {
      const {
        reference,
        service,
        customer,
        created_at,
        status,
        amount,
        customer_ref,
        plan,
      } = transaction;

      return [
        `${index + 1}`,
        reference,
        customer.name || customer.phone,
        customer.phone,
        customer_ref,
        service,
        amount,
        plan,
        created_at,
        status,
      ];
    });
  };

  const onExportClick = () => {
    setShowExportModal(true);
  };

  const onExportCloseClick = () => {
    setShowExportModal(false);
  };

  return (
    <>
      <ExportModal
        open={showExportModal}
        closeModal={onExportCloseClick}
        where={where}
        params={params}
      />
      <TransactionModal />
      <ExportModal />
      <GridContainer>
        {/* Query Fields */}
        <GridItem xs={12} sm={4} md={2}>
          <CustomInput
            labelText="From"
            // id="success"
            success={false}
            labelProps={{
              variant: "standard",
              // color: "primary",
              shrink: true,
              // disableAnimation: true,
            }}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "date",
              name: "from",
              value: params.from,
            }}
            onChange={setQueryParams}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={2}>
          <CustomInput
            labelText="To"
            // id="success"
            success={false}
            labelProps={{
              variant: "standard",
              // color: "primary",
              shrink: true,
              // disableAnimation: true,
            }}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "date",
              name: "to",
              value: params.to,
            }}
            onChange={setQueryParams}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <CustomInput
            clear={true}
            labelText="Query"
            // id="success"
            success={false}
            labelProps={{
              variant: "standard",
              // color: "primary",
              shrink: true,
              // disableAnimation: true,
            }}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "q",
              placeholder: "Meter No., account or reference",
            }}
            onChange={setQueryParams}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={2}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="select-label-status">Status</InputLabel>
            <Select
              labelId="select-label-status"
              name="status"
              onChange={setWhereClause}
              value={where.status}
            >
              <MenuItem value={""}>None</MenuItem>
              <MenuItem value={"SUCCESSFUL"}>SUCCESSFUL</MenuItem>
              <MenuItem value={"PENDING"}>PENDING</MenuItem>
              <MenuItem value={"FAILED"}>FAILED</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4} md={2}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="select-label-plan">Plan</InputLabel>
            <Select
              labelId="select-label-plan"
              name="plan"
              onChange={setWhereClause}
              value={where.plan}
            >
              <MenuItem value={""}>None</MenuItem>
              <MenuItem value={"PREPAID"}>PREPAID</MenuItem>
              <MenuItem value={"POSTPAID"}>POSTPAID</MenuItem>
            </Select>
          </FormControl>
        </GridItem>

        {/* Query Buttons */}
        <GridItem xs={6} md={2}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            // marginTop="27px"
            // ml="24px"
            mt="15px"
            mb="15px"
          >
            <Button
              disabled={loading}
              onClick={() => {
                search();
              }}
              color="primary"
            >
              Search
            </Button>
          </Box>
        </GridItem>
        <GridItem xs={6} md={2}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mt="15px"
            mb="15px"
          >
            <Button
              disabled={loading}
              onClick={() => {
                reset();
              }}
              color="danger"
            >
              RESET
            </Button>
          </Box>
        </GridItem>
        {/* End of Query Buttons */}

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display="flex">
                <Box flexGrow={1}>
                  <h4 className={classes.cardTitleWhite}>Transactions</h4>
                  <p className={classes.cardCategoryWhite}>
                    All Agent Transactions
                  </p>
                </Box>
                <Box display="flex" alignItems="flex-start" mr="5px">
                  <Button
                    style={{ color: "#932BAE", backgroundColor: "#FFFFFf" }}
                    onClick={onExportClick}
                  >
                    Export To CSV
                  </Button>
                  {loading && (
                    <CircularProgress style={{ color: "white" }} size={40} />
                  )}
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              {!loading && transactions.length <= 0 ? (
                <Typography>No transactions for this time range.</Typography>
              ) : transactions.length > 0 ? (
                <Table
                  tableHeaderColor="primary"
                  onRowClick={(e) =>
                    dispatch(openTransaction({ id: transactions[e[0] - 1].id }))
                  }
                  tableHead={[
                    "S/N",
                    "Reference",
                    "Customer",
                    "Phone No.",
                    "Meter No.",
                    "Service",
                    "Amount",
                    "Vend Type",
                    "Date",
                    "Status",
                  ]}
                  tableData={formattedTableData()}
                />
              ) : null}
              <Box display="flex" justifyContent="center" mt="14px">
                <Button
                  disabled={loading || page >= pages}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  color="success"
                >
                  Load more
                </Button>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default TableList;
