import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { axiosWithAuth } from "../../utils/auth";

const DOWNLOAD_LIMIT = 100;

const sanitizePayload = (data) =>
  Object.keys(data).reduce((acc, curr) => {
    if (data[curr]) {
      acc[curr] = data[curr];
    }

    return acc;
  }, {});

function convertJSONToCSV(jsonData, columnHeaders) {
  if (jsonData.length === 0) {
    return "";
  }

  const headers = columnHeaders.join(",") + "\n";

  const rows = jsonData
    .map((row) => {
      return columnHeaders.map((field) => row[field] || "").join(",");
    })
    .join("\n");

  return headers + rows;
}

const downloadCSV = (jsonData, headers, fileName = "export.csv") => {
  const csvData = convertJSONToCSV(jsonData, headers);

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function ExportModal({ open = false, closeModal, where, params }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const cancelTokenSource = useRef(null);
  const currentPage = useRef(1);

  async function loadData() {
    setIsLoading(true);
    let completeData = [...data];

    while (open) {
      if (cancelTokenSource.current?.token?.reason) break;

      try {
        const response = await axiosWithAuth().get(`/transactions`, {
          params: {
            page: currentPage.current,
            ...sanitizePayload(params),
            where: sanitizePayload(where),
            limit: DOWNLOAD_LIMIT,
          },
          cancelToken: cancelTokenSource.current.token,
        });

        const rows = response.data.data.rows;

        if (rows.length === 0) break;

        completeData = [...completeData, ...rows];
        currentPage.current++;

        setData(completeData);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled");
        } else {
          console.error("Error fetching data:", err);

          setError(
            "We cannot make this request happen at this time, please try again later"
          );
          setIsLoading(false);
        }

        break;
      }
    }

    if (completeData.length) {
      const queryParts = Object.values(sanitizePayload(params)).join("_");
      const whereParts = Object.values(sanitizePayload(where)).join("_");

      downloadCSV(
        completeData,
        [
          "reference",
          "amount",
          "service",
          "plan",
          "gateway",
          "order_id",
          "customer_ref",
          "response",
          "status",
          "created_at",
        ],
        `buypower_transactions${queryParts ? `_${queryParts}` : ""}${
          whereParts ? `_${whereParts}` : ""
        }`
      );
    }
  }

  useEffect(() => {
    if (open) {
      cancelTokenSource.current = axios.CancelToken.source();
      currentPage.current = 1;

      loadData();
    }

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Operation cancelled by the user");
      }
      setError("");
    };
  }, [open]);

  const onCancelButtonClick = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operation cancelled by the user");
    }
    closeModal();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Export To CSV</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <>
            <Typography style={{ fontSize: "16px", lineHeight: "24px" }}>
              Downloaded{" "}
              <span style={{ fontWeight: "700" }}>{data.length}</span> of many
            </Typography>

            <Box mt="15px">
              <LinearProgress style={{ height: "10px", borderRadius: "5px" }} />
            </Box>
          </>
        ) : null}

        {error ? <Typography color="error">{error}</Typography> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelButtonClick}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportModal;

ExportModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  where: PropTypes.object,
  params: PropTypes.object,
};
